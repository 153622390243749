import { gql } from '@apollo/client';

export const ORGANISATION_CARD_FRAGMENT = gql`
  fragment organisationCard on Organisation {
    id
    slug
    name
    email
    description
    teamMembers {
      id
      name
      email
      role
      user {
        id
        name
        email
      }
    }
    logo {
      name
      path
      thumb
    }
    cover {
      name
      path
      thumb
    }
    city {
      id
      name
      country {
        name
      }
    }
    social_links {
      url
      type
    }
  }
`;

export const ORGANISATION_ACCOUNT_FRAGMENT = gql`
  fragment orgAccount on Organisation {
    account {
      id
      name
      email
      default_currency
      charges_enabled
      payouts_enabled
    }
  }
`;

export const ORGANISATION_ADDITIONAL_INFO = gql`
  fragment organisationAdditionalInfo on Organisation {
    description
    website
    user {
      id
    }
  }
`;

export const ORGANISATION_ACCOUNT_INFO = gql`
  fragment accountInfo on Account {
    # id
    name
    email
    account_id
    # country_code
    business_type
    default_currency
    charges_enabled
    payouts_enabled
    has_external_account
    is_disabled
    disabled_reason
    # created_at
  }
`;

export const ACCOUNT_BALANCE = gql`
  fragment accountBalance on AccountBalanceDTO {
    available {
      amount
      currency
    }
    total {
      amount
      currency
    }
    
  }
`;

export const CONNECT_STEP = gql`
  fragment connectStep on ConnectStepDTO {
    is_done
    next
    url
  }
`;

export const EXTERNAL_ACCOUNT_BREIF = gql`
  fragment externalAccountBreif on ExternalAccount {
    id
    type
    country
    currency
    default_for_currency
    holder_name
  }
`;

const OrganisationFragment = {
  organisationCard: ORGANISATION_CARD_FRAGMENT,
  orgAccount: ORGANISATION_ACCOUNT_FRAGMENT,
  organisationAdditionalInfo: ORGANISATION_ADDITIONAL_INFO,
  accountInfo: ORGANISATION_ACCOUNT_INFO,
  accountBalance: ACCOUNT_BALANCE,
  connectStep: CONNECT_STEP,
  externalAccountBreif: EXTERNAL_ACCOUNT_BREIF,
};

export default OrganisationFragment;
