import { gql } from '@apollo/client';
import Fragments from '../fragments';

export const MY_ORGANISATIONS_QUERY = gql`
  query getOrganisation {
    myOrganisations {
      ...organisationCard
      ...orgAccount
    }
  }

  ${Fragments.orgAccount}
  ${Fragments.organisationCard}
`;

/**
 * @used organisation update
 */
export const GET_ORGANISATION_DETAILS = gql`
  query getOrganisation($slug: String!) {
    organisation(slug: $slug) {
      ...organisationCard
      ...organisationAdditionalInfo
      account {
        ...accountInfo
      }
    }
  }

  ${Fragments.accountInfo}
  ${Fragments.organisationCard}
  ${Fragments.organisationAdditionalInfo}
`;

export const MY_OPPORTUNITIES = gql`
  query getMyOpportunities($type: String, $organisation: String, $first: Int!, $page: Int) {
    myOpportunities(type: $type, organisation: $organisation, first: $first, page: $page) {
      paginatorInfo {
        ...paginator
      }
      data {
        ...opportunityCard
      }
    }
  }
  ${Fragments.opportunityCard}
  ${Fragments.paginator}
`;
export const SCIENTIST_OPPORTUNITIES = gql`
  query getScientistOpportunities($userId: String,  $first: Int!, $page: Int) {
    scientistOpportunities(userId: $userId, first: $first, page: $page) {
      paginatorInfo {
        ...paginator
      }
      data {
        ...opportunityCard
      }
    }
  }
  ${Fragments.opportunityCard}
  ${Fragments.paginator}
`;

export const MY_TRACKS = gql`
  query getMyTracks($type: String,$first: Int!, $page: Int) {
    myTracks(type: $type, first: $first, page: $page) {
      paginatorInfo {
        ...paginator
      }
      data {
        ...opportunityCard
      }
    }
  }
  ${Fragments.opportunityCard}
  ${Fragments.paginator}
`;

export const GET_ORGANISATION_ACCOUNT = gql`
  query organisationAccount($organisation: ID!, $recheck: Boolean) {
    organisationAccount(organisation: $organisation, recheck: $recheck) {
      ...accountInfo
    }
  }
  ${Fragments.accountInfo}
`;

export const ORGANISATION_ACCOUNT_DETAILS = gql`
  query organisationAccount($organisation: ID!, $slug: String!, $recheck: Boolean) {
    organisationAccount(organisation: $organisation, recheck: $recheck) {
      ...accountInfo
    }
    organisation(slug: $slug) {
      ...organisationCard
    }
     accountBalance(organisation: $organisation) {
       ...accountBalance
     }
  }
  ${Fragments.accountInfo}
  ${Fragments.organisationCard}
  ${Fragments.accountBalance}

`;

export const VERIFY_ACCOUNT_LINK = gql`
  query verifyAccountLink($organisation: ID!) {
    verifyAccountLink(organisation: $organisation) {
      ...connectStep
    }
  }
  ${Fragments.connectStep}
`;

export const ORGANISATION_PAYOUTS = gql`
  query organisationPayouts($organisation: String!) {
    organisationPayouts(organisation: $organisation) {
      id
      amount
      currency
      description
      failure_code
      #     account
      created_by {
        name
      }
      created_at
      arrival_date
    }
  }
`;

export const TEAM_MEMBERS = gql`
  query teamMembers($organisationId: ID!) {
    teamMembers(organisation_id: $organisationId) {
      id
      name
      email
      role
      organisation {
        id
        name
      }
      user {
        id
        name
        avatar {
          name
          path
          thumb
          placeholder
        }
      }
    }
  }
`;
